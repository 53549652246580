<template>
  <ModuleSubPage fullWidth title="SEMINAR_MANAGEMENT.LIST.TITLE">
    <BaseHasPermission :permissionNames="['management.seminar.read']">
      <SeminarTable />
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import SeminarTable from "@views/Restricted/SeminarManagement/Seminars/List/partials/SeminarTable.vue";
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";

export default {
  name: "SeminarManagementList",

  components: {
    SeminarTable,
    ModuleSubPage,
  },
};
</script>
