<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-spacer />
        <v-col cols="12" md="5" lg="4" xl="3">
          <BaseTableSearchField
            v-model="search"
            @triggerSearch="listSeminars"
            :loading="isLoading"
            :disabled="isLoading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-bind="$attrs"
        v-on="$listeners"
        :headers="headers"
        :options.sync="options"
        :items="seminars"
        :server-items-length="meta.total"
        :loading="isLoading"
      >
        <template #[`item.seminarStatus`]="{ item }">
          {{ item.seminarStatus.displayName }}
        </template>

        <template #[`item.title`]="{ item }">
          <span
            class="clickable"
            @click="$router.push({ name: 'SeminarManagementShow', params: { seminarId: item.id } })"
          >
            {{ item.title }}
          </span>
        </template>

        <template #[`item.startAt`]="{ item }">
          {{ date(item.startAt) }}
        </template>

        <template #[`item.endAt`]="{ item }">
          {{ date(item.endAt) }}
        </template>

        <template #[`item.isPublic`]="{ item }">
          <BaseStatusIndicator :status="item.isPublic" />
        </template>

        <template #[`item.isPublished`]="{ item }">
          <BaseStatusIndicator :status="item.isPublished" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import dateFilter from "@mixins/dateFilter";

export default {
  name: "SeminarTable",

  mixins: [dateFilter],

  computed: {
    headers() {
      return [
        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_TITLE"),
          value: "title",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_SUBTITLE"),
          value: "subTitle",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_SEMINAR_NUMBER"),
          value: "number",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_SEMINAR_TYPE"),
          value: "seminarType.displayName",
          sortable: false,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_START_AT"),
          value: "startAt",
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_END_AT"),
          value: "endAt",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_IS_PUBLIC"),
          value: "isPublic",
          align: "center",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_IS_PUBLISHED"),
          value: "isPublished",
          align: "center",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.LIST.TABLE_HEADER_STATUS"),
          value: "seminarStatus",
          sortable: true,
        },
      ];
    },
  },

  data() {
    return {
      options: {
        page: 1,
        perPage: 10,
        sortBy: ["startAt"],
        sortDesc: [false],
      },
      meta: {
        total: 0,
      },
      seminars: [],
      isLoading: true,
      search: "",
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.listSeminars();
      },
    },
  },

  methods: {
    async listSeminars() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.seminarManagement.listSeminars({
          ...this.options,
          search: this.search,
          perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
        });
        this.seminars = data.data;
        this.meta = data.meta;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listSeminars();
  },
};
</script>
